import 'slick-carousel';

jQuery(async function () {
	$('.home .slick-carousel').slick({
		dots: false,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 5,
		touchThreshold: 1000,
		slidesToScroll: 5,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}/*,
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			*/
		]
	});
	$('.mais-vendidos-carousel').slick({
		dots: false,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		rows: 2,
		slidesPerRow: 1,
		slidesToShow: 5,
		adaptiveHeight: true,
		touchThreshold: 1000,
		slidesToScroll: 1,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					rows: 1,
					slidesPerRow: 1,
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{	
				breakpoint: 768,
				settings: {
					rows: 1,
					slidesPerRow: 1,
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}/*,
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			*/
		]
	});
	$('.reposicao-carousel').slick({
		dots: false,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 8,
		adaptiveHeight: true,
		touchThreshold: 1000,
		slidesToScroll: 1,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{	
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}/*,
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			*/
		]
	});


    var previousActiveTabIndex = 0;

    $(".tab-switcher").on('click keypress', function (event) {
        // event.which === 13 means the "Enter" key is pressed

        if ((event.type === "keypress" && event.which === 13) || event.type === "click") {

            var tabClicked = $(this).data("tab-index");

			$(".tab-switcher").removeClass("active");
			$(this).addClass("active");

            if(tabClicked != previousActiveTabIndex) {
                $("#allTabsContainer .tab-container").each(function () {
                    if($(this).data("tab-index") == tabClicked) {
                        $(".tab-container").hide();
                        $(this).show();
                        previousActiveTabIndex = $(this).data("tab-index");
						
                        return;
                    }
                });
            }
        }
    });


	$(".product-thumb .product-quantity span, .product-thumb .product-quantity span, .product-thumb .product-quantity input[type=button]").on("click", function (e) {
		var alertsupply = $(this).parent().parent().parent().parent().parent().find('.alert.alertsupply');
		alertsupply.addClass('displaynone');
		var element = $(this).parent().find("input[name=amount]");
		var unit = parseInt(element.attr("data-unit"));
		var supply = parseInt(element.attr("data-supply"));
		if (unit == undefined) {
			unit = 1;
		}
		if (supply == undefined) {
			supply = 1;
		}
		if ($(this).hasClass('qtyminus')) {
			var act = "rem";
		} else {
			var act = "add";
		}
		var amount = parseInt(element.val());
		if (act == 'rem' && amount > unit) {
			amount = amount - unit;
		} else if (act == 'add') {
			if (supply >= (amount+unit)) {
				amount = amount + unit;
			} else {
				if (supply == 1) {
					alertsupply.find("span").html(supply+" item");
				} else {
					alertsupply.find("span").html(supply+" itens");
				}
				alertsupply.removeClass("displaynone");
			}
		}
		element.val(amount);
	});

	
});